<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 자료구분 -->
          <c-select
            :comboItems="comboDocumentTypeItems"
            itemText="codeName"
            itemValue="code"
            type="search"
            name="자료구분"
            v-model="searchParam.ramDocumentTypeCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <!-- 관련자료 목록 -->
    <c-table
      ref="table"
      title="관련자료 목록"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      :selection="popupParam.type"
      rowKey="documentId"
      @rowDblclick="rowDblclick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="검색" icon="search" @btnClicked="getList" />
          <c-btn v-if="editable" label="선택" icon="check" @btnClicked="select" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'riskRelationDocu',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'multiple',
        }
      },
    }
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'ramDocumentTypeName',
            field: 'ramDocumentTypeName',
            label: '분류',
            align: 'center',
            style: 'width:50%',
            sortable: false,
          },
          {
            name: 'documentTitle',
            field: 'documentTitle',
            description: 'documentSubTitle',
            label: '제목',
            align: 'left',
            style: 'width:50%',
            sortable: false,
          },
        ],
        data: [],
        height: '650px'
      },
      searchParam: {
        plantCd: null,
        ramDocumentTypeCd: null,
      },
      comboDocumentTypeItems: [],
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.ram.relatedDocument.addData.url;
      // code setting
      this.$comm.getComboItems('RAM_DOCUMENT_TYPE_CD').then(_result => {
        this.comboDocumentTypeItems = _result
      });
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
  }
};
</script>
